import React from "react"
import {Link} from "gatsby"
// import {
//     Button
// } from '@adobe/react-spectrum';

import Layout from "../components/layout"
import AlienImage from "../components/alienImage"
import SEO from "../components/seo"
import TestTube from "../components/testTube";
import MedicalResearchImage from "../components/medicalResearchImage";
// import PatientImage from "../components/patientImage";
import HeroDivider from "../components/heroDivider";
import HeroImage from "../components/heroImage";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <h1 className='text-4xl text-center mt-8'>Atlee Biotech, Inc.</h1>
        <span className='text-2xl text-center'>Developing the future of medicine
                </span>
      <div className='grid justify-center p-8'>
        <Link className='text-2xl inline-block px-6 py-4 font-medium leading-6
        text-center text-white uppercase transition bg-blue-700 rounded-full
        shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none'
              to='/contact'>
          Contact us today!
        </Link>
      </div>

        <div className='mx-auto' style={{ width: '50%' }}>
            <HeroImage />
        </div>
        <div className='mt-8 mb-12'>
            <HeroDivider />
        </div>

        {/* for animations, see https://www.gatsbyjs.com/plugins/gatsby-plugin-scroll-reveal/ */}

        <div className='grid grid-cols-2 gap-4 items-center px-4 md:p-8 mb-12' data-sal='fade'>
            <div>
                <span className='text-2xl text-center'>Atlee Biotech's laboratories focus on developing drugs to treat
                    a wide variety of disorders
                </span>
            </div>
            <div><TestTube /></div>
        </div>

        <div className='mb-12' style={{height: '150px', overflow: 'hidden'}} data-sal='fade'>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                <path d="M0.00,49.99 C150.00,150.00 349.20,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#328cc1'}}/>
            </svg>
        </div>


        {/* Row 2 start */}
        <div className='grid grid-cols-2 gap-4 items-center px-4 md:p-8 mb-12' data-sal='fade'>
            <div><MedicalResearchImage /></div>
            <span className='text-2xl text-center mt-2'>
                Super-acute therapeutics for disorders with an unmet need
            </span>
        </div>
        <div className='mb-12' style={{height: '150px', overflow: 'hidden'}} data-sal='fade'>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                <path d="M0.00,49.99 C150.00,150.00 349.20,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#328cc1'}}/>
            </svg>
        </div>
        {/* Row 2 end */}

        {/* Row 3 start */}
        <div className='grid grid-cols-2 gap-4 items-center px-4 md:p-8 mb-12' data-sal='fade'>
            <span className='text-2xl text-center mt-2'>Novel treatments for acute disorders</span>
            <div>
                {/*<div className='object-center' style={{ maxWidth: `500px`, marginBottom: `1.45rem`, marginLeft: `9rem`}}>*/}
                <AlienImage />
                {/*</div>*/}
            </div>
        </div>
        {/*<div className='mb-12' style={{height: '150px', overflow: 'hidden'}} data-sal='fade'>*/}
        {/*    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>*/}
        {/*        <path d="M0.00,49.99 C150.00,150.00 349.20,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#328cc1'}}/>*/}
        {/*    </svg>*/}
        {/*</div>*/}
        {/* Row 3 end */}

        {/* Row 4 start */}
        {/*<div className='grid grid-cols-2 gap-4 items-center px-4 md:p-8' data-sal='fade'>*/}
        {/*    <div><PatientImage /></div>*/}
        {/*    <span className='text-2xl text-center mt-2'>Predict which patients are most likely to be readmitted.</span>*/}
        {/*</div>*/}
        {/* Row 4 end */}
    </Layout>
)

export default IndexPage