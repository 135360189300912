import React from "react"
import { StaticImage} from "gatsby-plugin-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const HeroImage = () => {
//   const data = useStaticQuery(graphql`{
//   placeholderImage: file(relativePath: {eq: "undraw_medical_care_movn.png"}) {
//     childImageSharp {
//       gatsbyImageData(width: 300, layout: FULL_WIDTH)
//     }
//   }
// }
// `)
//
//   if (!data?.placeholderImage?.childImageSharp?.gatsbyImageData) {
//     return <div>Picture not found</div>
//   }

  return <StaticImage
      src="../images/undraw_medical_care_movn.png"
      alt="medical care Image"
      placeholder="blurred"
      layout="fullWidth"
  />

}

export default HeroImage
